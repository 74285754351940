import React from 'react';
import {Row, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Section from './section';
import DGLVPixel from './pixels/onLoad/DGLVPixel';
import DGLVButton from './pixels/onClick/DGLVButton';

const SectionSubscribe = ({className, gray}) => {
  const classes = classnames('section__subscribe', className);
  return (
    <Section className={classes} gray={gray}>
      <Row>
        <Col md={9}>
          Sign up for news and updates from St. Jude Cloud, including when new studies are added,
          conferences we’ll be attending, and research published with data from St. Jude Cloud.
        </Col>
        <Col md={3} className="button-container">
          <DGLVButton
            href="https://hospital.stjude.org/apps/forms/fb/st-jude-cloud-subscribe/"
            id="3c6f72"
            buttonText="Subscribe"
          />
          <DGLVPixel id="3c6f72" />
        </Col>
      </Row>
    </Section>
  );
};

SectionSubscribe.propTypes = {
  className: PropTypes.string,
  gray: PropTypes.string,
};

export default SectionSubscribe;
