import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {Button, Row, Col, Container} from 'react-bootstrap';
import Img from 'gatsby-image';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/section';
import SubscribeSection from '../../components/section-subscribe';
import DomainHero from '../../components/domain-hero';
import StudyCard from '../../components/card-study';
import CardAppPlatform from '../../components/card-app-platform';
import CardAppVizComm from '../../components/card-app-vizcomm';

const CancerSurvivorship = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "icon_vizcomm.png"}) {
        id
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Cancer Survivorship" />
      <div className="cancer-survivorship-domain">
        <DomainHero
          fileName="robison-zhang.png"
          caption={
            <>
              Mine the data. <br className="d-md-none d-lg-block" /> Find the answers.
            </>
          }
          label="Jinghui Zhang, Ph.D. and Les Robison, Ph.D."
        />
        <div>
          <Section className="desc-section">
            <Row>
              <Col>
                <h1>Cancer Survivorship</h1>
                <p className="fw-bold">Welcome to the Cancer Survivorship Research Domain</p>
                <p>
                  This page is a collection of information about cancer survivorship on St. Jude
                  Cloud. Use this page to browse our cancer survivorship studies, which are the
                  different datasets we offer on St. Jude Cloud, or discover the possibilities of
                  data curation with our list of recommended apps for working with cancer
                  survivorship data. St. Jude Cloud shares high-quality genomic, clinical and
                  patient-reported data from survivors of pediatric cancer. To accelerate the rate
                  of discovery in survivorship research, we provide both raw and curated data for
                  easy browsing, visualizing and analyzing of clinical and genetic data. Get updates
                  and important links on this page to know what St. Jude Cloud is up to next!
                </p>
              </Col>
            </Row>
          </Section>

          <Container fluid className="featured__icon__container--multiple">
            <Row>
              <Col md={8} className="featured__icon__col">
                <Img fixed={data.file.childImageSharp.fixed} className="featured__icon" />
              </Col>
            </Row>
          </Container>

          <Section gray>
            <Row>
              <Col md={{span: 11, offset: 1}} className="featured__icon__col--responsive">
                <Img fixed={data.file.childImageSharp.fixed} className="featured__icon" />
              </Col>
              <Col md={{span: 11, offset: 1}}>
                <strong>FEATURED VISUALIZATION</strong>
                <h4 className="featured__header">
                  St. Jude Survivorship Community
                </h4>
                <p>
                  Explore cancer-related variables such as diagnosis and treatment, as well as
                  demographic variables, clinical outcomes and secondary cancer diagnoses.
                  Severity-graded chronic health conditions are also included using a modified
                  version of the Common Terminology Criteria for Adverse Events. Dictionary terms
                  from these categories are arranged hierarchically from general to specific. The
                  structure allows a user to navigate the dictionary tree while viewing patient
                  distributions with user-selected terms. Data can be organized as customizable bar
                  charts or cross-tabulations.
                </p>
                <Button
                  href="https://viz.stjude.cloud/community/cancer-survivorship-community~4"
                  variant="cancer-survivorship"
                >
                  View Community
                </Button>
                <Button
                  style={{ marginLeft: "10px" }}
                  href="https://viz.stjude.cloud/cancer-survivorship/visualization/st-jude-survivorship-portal~18"
                  variant="cancer-survivorship"
                >
                  View Survivorship Portal
                </Button>
                <Button
                  style={{ marginLeft: "10px" }}
                  href="https://viz.stjude.cloud/community/cancer-survivorship-community~4/publications"
                  variant="cancer-survivorship"
                >
                  View Publications
                </Button>
              </Col>
            </Row>
          </Section>

          <Section>
            <Row>
              <Col xs={12}>
                <h2>Apps</h2>
                <p>
                  St. Jude Cloud is comprised of multiple applications that allow you to interact
                  with our data in different ways.
                </p>
              </Col>
            </Row>
            <Row>
              <CardAppPlatform
                lg={6}
                url="https://platform.stjude.cloud/data/cohorts/cancer-survivorship"
              />
              <CardAppVizComm
                lg={6}
                url="https://viz.stjude.cloud/visualizations?research-domain=cancer-survivorship"
              />
            </Row>
          </Section>

          <Section gray>
            <Row className="mb-3">
              <Col xs={12}>
                <h2>Studies</h2>
                <p>
                  Cohorts of two large pediatric cancer survivorship studies have been sequenced and
                  released on St. Jude Cloud. Learn about our studies.
                </p>
              </Col>
            </Row>
            <Row className="studies__list">
              <StudyCard title="St. Jude LIFE" url="https://sjlife.stjude.org/" external />
              <StudyCard
                title="Childhood Cancer Survivor Study"
                url="https://ccss.stjude.org/"
                external
              />
            </Row>
          </Section>

          <SubscribeSection />
        </div>
      </div>
    </Layout>
  );
};

export default CancerSurvivorship;
